import {configureStore} from '@reduxjs/toolkit'
import guestApi from "./guest-api";
import productToCartSlice from "./slices/product-to-cart";
import {catalogElementSlice} from "./slices/catalog-element";
import {catalogSectionElementsSlice} from "./slices/catalog-section-elements";
import {catalogFilterSlice} from "./slices/catalog-filter";
import {catalogSortSlice} from "./slices/catalog-sort";
import {adminSlice} from "./slices/admin";
// ...

export const store = configureStore({
    reducer: {
        [guestApi.reducerPath]: guestApi.reducer,

        CatalogElement: catalogElementSlice.reducer,
        CatalogSectionElements : catalogSectionElementsSlice.reducer,
        CatalogFilter : catalogFilterSlice.reducer,
        CatalogSort: catalogSortSlice.reducer,

        ProductToCart: productToCartSlice.reducer,

        admin : adminSlice.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(guestApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
