import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import GeneralRouter from './components/GeneralRouter';
import {Provider} from 'react-redux'
import {store} from './redux/store'
import {ThemeProvider} from "styled-components";
import defaultTheme from "./theme";
import {SnackbarProvider} from "notistack";
import {SkeletonTheme} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {createRoot} from "react-dom/client";
import {Helmet, HelmetProvider} from "react-helmet-async";

const AdminParserLauncher = React.lazy(() => import('./components/AdminParserLauncher'));


// are we in admin page?
const $adminCont1 = document.querySelector('.TJS_AdminParserLauncher_Cont');
if ($adminCont1) {
    console.log("Initiaeing...");
    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={<div>Загрузка...</div>}>
                <AdminParserLauncher sectionID={$adminCont1.dataset.sectionId}/>
            </Suspense>
        </React.StrictMode>,
        $adminCont1
    );
} else {
    const domNode = document.getElementById('ts_react-app-root');
    const root = createRoot(domNode);

    root.render(<React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <title>Стройся Волга</title>
                <link rel="canonical" href="https://sv-nn.com/"/>
            </Helmet>
            <Provider store={store}>
                <ThemeProvider theme={defaultTheme}>
                    <SkeletonTheme>
                        <SnackbarProvider>
                            <GeneralRouter/>
                        </SnackbarProvider>
                    </SkeletonTheme>
                </ThemeProvider>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>);
    // ReactDOM.render(
    //     <React.StrictMode>
    //         <Provider store={store}>
    //             <ThemeProvider theme={defaultTheme}>
    //                 <SkeletonTheme>
    //                     <SnackbarProvider>
    //                         <GeneralRouter/>
    //                     </SnackbarProvider>
    //                 </SkeletonTheme>
    //             </ThemeProvider>
    //         </Provider>
    //     </React.StrictMode>,
    //     document.getElementById('ts_react-app-root')
    // );
}



