import React from "react";

class Metrica extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			admin: (window.localStorage.getItem("admin") === 'Y')
		};
	}
	
	componentDidMount() {
		if (this.state.admin || process.env.REACT_APP_OMIT_METRICS === "Y")
			return;
		try {
			this.leadback()
		} catch (e) {
			console.warn(e);
		}
		try {
			this.ymetrics()
		} catch (e) {
			console.warn(e);
		}
		/*try {
			this.victorycorp()
		} catch (e) {
			console.warn(e);
		}*/
		setTimeout(() => {
			try {
				this.mangooffice()
			} catch (e) {
				console.warn(e);
			}
		}, 1000);
	}
	
	/*victorycorp() {
		
		(function (d, w) {
			var n = d.getElementsByTagName("script")[0],
				s = d.createElement("script");
			s.type = "text/javascript";
			s.async = true;
			s.src = "https://victorycorp.ru/index.php?ref=" + d.referrer + "&page=" + encodeURIComponent(w.location.href);
			n.parentNode.insertBefore(s, n);
			console.log('victorycorp', s);
		})(document, window);
	}*/
	
	leadback() {
		window._emv = window._emv || [];
		window._emv['campaign'] = 'b0f169f34a2db30564d287d6';
		
		(function () {
			var em = document.createElement('script');
			em.type = 'text/javascript';
			em.async = true;
			em.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'leadback.ru/js/leadback.js';
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(em, s);
		})();
	}
	
	ymetrics() {
		(function (m, e, t, r, i, k, a) {
			m[i] = m[i] || function () {
				(m[i].a = m[i].a || []).push(arguments)
			};
			m[i].l = 1 * new Date();
			k = e.createElement(t);
			a = e.getElementsByTagName(t)[0];
			k.async = 1;
			k.src = r;
			a.parentNode.insertBefore(k, a);
		})
		(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
		
		window.ym(51042350, "init", {
			clickmap: true,
			trackLinks: true,
			accurateTrackBounce: true,
			webvisor: true
		});
	}
	
	mangooffice() {
		console.log('mangooffice');
		(function (w, d, u, i, o, s, p) {
			if (d.getElementById(i)) {
				return;
			}
			w['MangoObject'] = o;
			w[o] = w[o] || function () {
				(w[o].q = w[o].q || []).push(arguments)
			};
			w[o].u = u;
			w[o].t = 1 * new Date();
			s = d.createElement('script');
			s.async = 1;
			s.id = i;
			s.src = u;
			p = d.getElementsByTagName('script')[0];
			p.parentNode.insertBefore(s, p);
		}(window, document, '//widgets.mango-office.ru/widgets/mango.js', 'mango-js', 'mgo'));
		window.mgo({
			calltracking: {
				id: 30596, elements: [
					{"numberText": "78312621085"},
					{"numberText": "+7 (831) 262-10-85"}
				], domain: 'sv-nn.com'
			}
		});
	}
	
	static reachGoal(name) {
		try {
			console.log('reachGoal', name);
			window.ym(51042350, 'reachGoal', name);
		} catch (e) {
			console.log("RG: " + name);
		}
	}
	
	render() {
		if (this.state.admin) {
			return (
				<noscript>
					<div>
						<img
							src="https://mc.yandex.ru/watch/51042350"
							style="position:absolute; left:-9999px;"
							alt=""
						/>
					</div>
				</noscript>
			);
		}
		return <div className="m3tr1x"/>;
	}
}

export default Metrica;
