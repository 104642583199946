import guestApi from "../index";

export const CatalogElementApi = guestApi.injectEndpoints({
    endpoints: build => ({
        getCatalogElement: build.query({
            query : (args) => ({
                url: "/api/catalog/element",
                method: "POST",
                body: args,
            })
        }),
        getOffertsList: build.query({
            query : (args) => ({
                url: "/api/catalog/element-offerts",
                method: "POST",
                body: args,
            })
        }),

    })
})


export const {
    useGetCatalogElementQuery,
    useGetOffertsListQuery,
} = CatalogElementApi
