import {createSlice} from "@reduxjs/toolkit";
import FilterApi from "../guest-api/catalog/filter";
import {CatalogSectionElementsProps} from "../../types/catalog-section-elements";

const initialState = null as unknown as CatalogSectionElementsProps[];

export const catalogSectionElementsSlice = createSlice({
    name: "CatalogSectionElements",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(FilterApi.endpoints.getFilterData.matchFulfilled, (state, action) => {
            return action.payload.elements;
        })
    },
})
