import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/react";

export const _baseQuery =
    (
        {baseUrl}: { baseUrl: string } = {baseUrl: ''}
    ): BaseQueryFn<{
        url: string
        method: "POST" | "GET",
        body: Object
    },
        unknown,
        unknown> =>
        async ({url, method, body}) => {
            try {
                const formData = new FormData();

                for (let key in body) {
                    // @ts-ignore
                    formData.append(key, body[key].toString());
                }
                const resp = await fetch(baseUrl + url, {
                    method: method,
                    body: formData
                });

                const result = await resp.json();

                if ('is404' in result) return {
                    error: {
                        is404: true,
                    }
                }

                return {data: result}
            } catch (error) {
                return {
                    error: error,
                }
            }
        }
