import {createSlice} from "@reduxjs/toolkit";

export type ProductToCartProps = {
    offer: number,
    options: { [optionCode: string]: string[] },
}

const initialState: ProductToCartProps = {
    offer: 0,
    options: {},
}


const productToCartSlice = createSlice({
    name: "ProductToCart",
    initialState,
    reducers: {
        setOffer: (state, action) => {
            state.offer = action.payload as number;
            return state;
        },
        // setFundament: (state, {payload}) => {
        //     state.fundament = payload;
        //     return state;
        // },
        updateOption: (state, {payload}) => {
            state.options[payload.code] = payload.values;
            return state
        },
        setDefaults: (state, {payload}) => {
            return {...payload};
        },
    }
});

export default productToCartSlice
