import guestApi from "../index";

const FilterApi = guestApi.injectEndpoints({
    endpoints: build => ({
        getFilterData: build.query({
            query: (args) => ({
                url: "/api/catalog/filter",
                method: "POST",
                body: args,
            })
        }),
    })
});

export const {useGetFilterDataQuery} = FilterApi

export default FilterApi;
