import React from 'react';
import './preloader.scss';

class Preloader extends React.Component {
	render() {
		return (
			<div className="ts_total-preloader">
				<div className="ts_preloader-cont">
					<div className="loader">
						<div className="circle"/>
						<div className="circle"/>
						<div className="circle"/>
						<div className="circle"/>
						<div className="circle"/>
					</div>
				</div>
			</div>
		);
	}
}

export default Preloader;