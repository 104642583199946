import {createSlice} from "@reduxjs/toolkit";

const initialState = ()=>{
    const token = sessionStorage.getItem('isAdmin');

     return  token !== null;
};

export const adminSlice = createSlice({
    name: "admin",
    reducers: {
        setUser: (state, action)=>{
            if ("login" in action.payload && action.payload.login !== "supadmin") return false;
            if ("password" in action.payload && action.payload.password !== "supadmin") return false;

            return true;
        },
    },
    initialState: ()=>initialState(),
});
