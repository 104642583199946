import React, {Suspense, useEffect} from 'react';
import Preloader from './Util/Preloader/Preloader';
import './Styles/common.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Metrica from "./Util/Metrica";

const Error404 = React.lazy(() => import('./Pages/Error404'));
const Index = React.lazy(() => import('./Pages/Index'));
const CatalogMainPage = React.lazy(() => import('./Pages/CatalogMainPage'));
const TestPage = React.lazy(() => import('./Pages/TestPage'));
const CatalogSection = React.lazy(() => import('./Pages/CatalogSection'));
const ChainLoaderImporter = React.lazy(() => import('./Pages/ChainLoaderImporter'));
const RemoteBlockpage = React.lazy(() => import('./Pages/RemoteBlockpage'));
const CreditInfoPage = React.lazy(() => import('./Pages/CreditInfoPage'));
const OurObjectCasesPage = React.lazy(() => import('./Pages/OurObjectCasesPage'));
const AboutPage = React.lazy(() => import('./Pages/AboutPage'));
const ContactsPage = React.lazy(() => import('./Pages/ContactsPage'));
const RemoteFetchedPage = React.lazy(() => import('./Pages/RemoteFetchedPage'));
const Calculator2Pdf = React.lazy(() => import('./Pages/Calculator2Pdf'));


const HomePage = React.lazy(()=>import('./Pages/HomePage'));
const Catalog2023Page = React.lazy(() => import("./Pages/Catalog2023"));
const Catalog2023SectionElementsPage = React.lazy(() => import("./Pages/Catalog2023SectionElements"));
const Catalog2023DetailPage = React.lazy(() => import("./Pages/Catalog2023Detail"));
const OffertsConfiguratorPage = React.lazy(() => import("./Pages/OffertsConfigurator"));


/*export const history = createHistory();

history.listen((location, action) => {
	window.scrollTo(0, 0)
});*/

/*
const ScrollToTop = (props) => {
	const {pathname} = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return <>{props.children}</>
};*/

if ('scrollRestoration' in window.history) {
    // Back off, browser, I got this...
    window.history.scrollRestoration = 'manual';
}

const routes = [
    {path: "/", component: HomePage},
    // Каталог товаров
    {path: "/catalog/", component: Catalog2023Page},
    {path: "/catalog/:sectionCode/", component: Catalog2023SectionElementsPage},
    {path: "/catalog/:sectionCode/:elementCode/", component: Catalog2023DetailPage},
    //
    {path: "/offerts-configurator/", component: OffertsConfiguratorPage},
];

const GeneralRouter = () => {
    const $pageOffset = process.env.REACT_APP_ROUTER_START_OFFSET;
    const ref = React.useRef(null);
    useEffect(() => {
        if (ref.current)
            ref.current.scrollTo(0, 0);
    }, [ref]);

    return (
        <div ref={ref}>
            <Router>
                <Switch>
                    {
                        routes.map((route) => {
                            return <Route path={$pageOffset + route.path} exact key={route.path}>
                                <Suspense fallback={Preloader}>
                                    <route.component/>
                                </Suspense>
                            </Route>
                        })
                    }



                    <Route path={$pageOffset + '/pages/communications/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="communications"/>
                        </Suspense>
                    </Route>
                    <Route path={$pageOffset + '/pages/contacts/'} exact>
                        <Suspense fallback={Preloader}>
                            <ContactsPage/>
                        </Suspense>
                    </Route>

                    <Route path={$pageOffset + '/pages/credits/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="credits"/>
                        </Suspense>
                    </Route>


                    <Route path={$pageOffset + '/pages/furniture/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="furniture"/>
                        </Suspense>
                    </Route>

                    <Route path={$pageOffset + '/pages/mothercap/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="mothercap"/>
                        </Suspense>
                    </Route>


                    <Route path={$pageOffset + '/pages/individual-project/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="individual-project"/>
                        </Suspense>
                    </Route>

                    <Route path={$pageOffset + '/pages/restoration/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="restoration"/>
                        </Suspense>
                    </Route>


                    <Route path={$pageOffset + '/pages/techboard/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="techboard"/>
                        </Suspense>
                    </Route>
                    <Route path={$pageOffset + '/pages/actions/'} exact>
                        <Suspense fallback={Preloader}>
                            <RemoteBlockpage code="actions"/>
                        </Suspense>
                    </Route>


                    <Route path={$pageOffset + '/pages/cases/'} exact>
                        <Suspense fallback={Preloader}>
                            <OurObjectCasesPage/>
                        </Suspense>
                    </Route>


                    <Route path={$pageOffset + '/pages/about/'} exact>
                        <Suspense fallback={Preloader}>
                            <AboutPage/>
                        </Suspense>
                    </Route>

                    <Route path={$pageOffset + '/chainloader/'} exact>
                        <Suspense fallback={Preloader}>
                            <ChainLoaderImporter/>
                        </Suspense>
                    </Route>

                    <Route path={$pageOffset + '/calculator/2pdf/:dataset/'} exact>
                        <Suspense fallback={Preloader}>
                            <Calculator2Pdf/>
                        </Suspense>
                    </Route>

                    <Route path='*' exact={true}>
                        <Suspense fallback={Preloader}>
                            <Error404/>
                        </Suspense>
                    </Route>

                </Switch>
            </Router>
            <Metrica/>
        </div>
    );
};

export default GeneralRouter;
