import {createSlice} from "@reduxjs/toolkit";
import FilterApi from "../guest-api/catalog/filter";
import {FilterProps} from "../../types/filter";

const initialState = null as unknown as FilterProps;

export const catalogFilterSlice = createSlice({
    name: "CatalogFilter",
    initialState,
    reducers: {
        update: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(FilterApi.endpoints.getFilterData.matchFulfilled, (state, action) => {
            return action.payload.filter;
        })
    },
})
