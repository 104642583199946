import {createSlice} from "@reduxjs/toolkit";
import {CatalogSortProps, CatalogSortPropsBy} from "../../types/catalog-sort";
import {SortDirection} from "../../types/sort-direction";
import appConfig from "../../app-config";

const initialState: CatalogSortProps = {
    by: undefined,
    direction: undefined,
}

export const catalogSortSlice = createSlice({
    name: "CatalogSort",
    initialState,
    reducers: {
        set : (state, action) => {
            return action.payload
        }
        // update : (state, action) => {
        //     localStorage.setItem(KEY, JSON.stringify(action.payload));
        //     return action.payload
        // }
    },
})
