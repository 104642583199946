import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {_tagTypes} from "./_tag-types";
import {_baseQuery} from "./_base-query";

const guestApi = createApi({
    // baseQuery: fetchBaseQuery({
    //     baseUrl: process.env.REACT_APP_AJAX_SERVER,
    // }),
    baseQuery: _baseQuery({
        baseUrl: process.env.REACT_APP_AJAX_SERVER as string,
    }),
    reducerPath: "guestApi",
    tagTypes: _tagTypes,
    endpoints: () => ({}),
});

export default guestApi;
