import {createSlice} from "@reduxjs/toolkit";
import {CatalogElementProps} from "../../components/Blocks/CatalogDetail/types";
import {CatalogElementApi} from "../guest-api/catalog/detail";

const initialState : CatalogElementProps = null as unknown as CatalogElementProps;

export const catalogElementSlice = createSlice({
    name: "CatalogElement",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(CatalogElementApi.endpoints.getCatalogElement.matchFulfilled, (state, action) => {
            return action.payload;
        })
    },
})
